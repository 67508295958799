<template>
  <StrapiActualiteComponent
    v-if="itemActif"
    :item="itemActif"
    :items="autresItems"
    :expand="true"
  ></StrapiActualiteComponent>
</template>

<script>
import StrapiActualiteComponent from "../../components/strapi/StrapiActualiteComponent.vue";
import StrapiService from "../../services/strapi.service";
export default {
  name: "StrapiActualiteView",
  components: { StrapiActualiteComponent },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    itemActif() {
      return this.items.find((i) => i.id == this.$route.params.id);
    },
    autresItems() {
      return this.items.filter((i) => i.id !== this.itemActif.id);
    },
    collection() {
      return this.$store.getters["strapi/collection"]("actualites");
    },
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
  },
  mounted() {
    // Si l'utilisateur n'est pas autorisé, on quitte la page
    if (!this.$store.getters['auth/hasAccesStrapiActualites']) {
      this.$router.push({ name: 'accueil' });
    }
    this.load();
  },
  watch: {
    $route() {
      this.load();
    },
  },
  methods: {
    load() {
      this.items = [];
      return StrapiService.getCollection(
        this.collection,
        this.exploitationCourante.codeSociete
      ).then((response) => {
        this.items = this.collection.sort
          ? response.data.data.sort(this.collection.sort)
          : response.data.data;
      });
    },
  },
};
</script>
